<template>
  <div id="userOrder" v-loading.fullscreen.lock="fullscreenLoading">
    <Dialog ref="dialog" :config="config" />
    <nav>
      <span
        @click="
          form.status = 'all';
          $forceUpdate();
          form.page = 1;
          getData(form);
        "
        :class="form.status == 'all' ? 'active' : ''"
        ><span class="label">{{ $fanyi("所有订单") }}</span
        ><span class="numberCount">{{ ordersSumList.all }}</span></span
      >
      <span
        @click="
          form.status = 'temporary';
          $forceUpdate();
          form.page = 1;
          getData(form);
        "
        :class="form.status == 'temporary' ? 'active' : ''"
        ><span class="label">{{ $fanyi("临时保存的订单") }}</span
        ><span class="numberCount">{{ ordersSumList.temporary }}</span></span
      >

      <span
        @click="
          form.status = 'offer';
          $forceUpdate();
          form.page = 1;
          getData(form);
        "
        :class="form.status == 'offer' ? 'active' : ''"
        ><span class="label">{{ $fanyi("报价中") }}</span
        ><span class="numberCount">{{ ordersSumList.offer }}</span></span
      >

      <span
        @click="
          form.status = 'obligation';
          $forceUpdate();
          form.page = 1;
          getData(form);
        "
        :class="form.status == 'obligation' ? 'active' : ''"
        ><span class="label">{{ $fanyi("待处理的订单") }}</span
        ><span class="numberCount">{{ ordersSumList.obligation }}</span></span
      >
      <span
        @click="
          form.status = 'delete';
          $forceUpdate();
          form.page = 1;
          getData(form);
        "
        :class="form.status == 'delete' ? 'active' : ''"
        ><span class="label">{{ $fanyi("已取消的订单") }}</span
        ><span class="numberCount">{{
          ordersSumList.delete + ordersSumList.cancel + 0
        }}</span></span
      >
    </nav>
    <div>
      <div class="searchBox">
        <div class="myInput">
          <i class="el-icon-search"></i>
          <input
            type="text"
            v-model="form.searchText"
            @keydown.enter="getData(form)"
            :placeholder="$fanyi('请输入关键词搜索')"
          />
        </div>
        <el-date-picker
          v-model="value1"
          type="datetimerange"
          :range-separator="$fanyi('至')"
          :start-placeholder="$fanyi('开始日期')"
          :end-placeholder="$fanyi('结束日期')"
          format="dd-MM-yyyy HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']"
          @change="setFromDate"
        >
        </el-date-picker>
        <button
          class="searchBtn"
          @click="
            form.page = 1;
            getData(form);
          "
        >
          {{ $fanyi("搜索") }}
        </button>
      </div>
      <div class="dataBody">
        <ul class="ohead">
          <li class="oNO"><span class="tableHeadCon">Nº</span></li>
          <li class="orderNumber">
            <span class="tableHeadCon" :title="$fanyi('订单号')">{{
              $fanyi("订单号")
            }}</span>
          </li>
          <li class="datePresentation" v-if="form.status != 'temporary'">
            <span class="tableHeadCon" :title="$fanyi('提出日期')">{{
              $fanyi("提出日期")
            }}</span>
          </li>
          <li
            class="datePresentation"
            v-if="form.status != 'temporary' && form.status != 'obligation'"
          >
            <span class="tableHeadCon" :title="$fanyi('付款日期')">{{
              $fanyi("付款日期")
            }}</span>
          </li>
          <li
            class="datePresentation"
            v-if="form.status != 'temporary' && form.status != 'obligation'"
          >
            <span class="tableHeadCon" :title="$fanyi('购买完成日期')">
              {{ $fanyi("购买完成日期") }}</span
            >
          </li>
          <li
            class="upLoad"
            v-if="['purchase', 'purchased'].indexOf(form.status) != -1"
          >
            <span class="tableHeadCon" :title="$fanyi('导出excel')">{{
              $fanyi("导出excel")
            }}</span>
          </li>
          <li style="text-align: center">
            <span class="tableHeadCon" :title="$fanyi('订单状态')">{{
              $fanyi("订单状态")
            }}</span>
          </li>
          <li v-if="['temporary', 'obligation'].indexOf(form.status) != -1">
            <span class="tableHeadCon" :title="$fanyi('操作')">{{
              $fanyi("操作")
            }}</span>
          </li>
        </ul>
        <ul
          class="obody"
          v-for="(item, index) in tableData.data"
          :key="$fanyi('表格') + index"
        >
          <li class="oNO">{{ index + 1 }}</li>
          <li class="orderNumber">
            <button
              @click="
                $fun.routerToPage(
                  '/OrderDetails?type=' +
                    item.statusName +
                    '&order_sn=' +
                    item.order_sn,
                  true
                )
              "
            >
              {{ item.order_sn }}
            </button>
          </li>
          <li class="datePresentation" v-if="form.status != 'temporary'">
            {{ item.created_at }}
          </li>
          <li
            class="datePresentation"
            v-if="form.status != 'temporary' && form.status != 'obligation'"
          >
            {{
              item.order_time_node
                ? item.order_time_node.datetime_pay_confirm
                  ? item.order_time_node.datetime_pay_confirm
                  : "————"
                : "————"
            }}
          </li>
          <li
            class="datePresentation"
            v-if="form.status != 'temporary' && form.status != 'obligation'"
          >
            {{
              item.order_time_node
                ? item.order_time_node.datetime_purchase_complete
                  ? item.order_time_node.datetime_purchase_complete
                  : "————"
                : "————"
            }}
          </li>
          <li
            class="upLoad"
            v-if="['purchase', 'purchased'].indexOf(form.status) != -1"
            @click="getFapiao(item.order_sn)"
          >
            <img src="../../../../assets/newImg/shancg2.svg" alt="" />
          </li>
          <li style="font-size: 12px; text-align: center">
            {{
              item.is_offer_error
                ? $fanyi("事故")
                : textConversion(item.statusName)
            }}
          </li>
          <li v-if="['temporary', 'obligation'].indexOf(form.status) != -1">
            <span style="cursor: pointer" @click="delOrder(item.order_sn)">{{
              $fanyi("取消")
            }}</span>
            <!--            <span v-if="form.status==='obligation'"  style="cursor: pointer;display: inline-block;margin-left: 30px" @click="-->
            <!--                $fun.routerToPage(-->
            <!--                  '/OrderDetails?type=' +-->
            <!--                    item.statusName +-->
            <!--                    '&order_sn=' +-->
            <!--                    item.order_sn-->
            <!--                )-->
            <!--              ">{{-->
            <!--                $fanyi("前往付款")-->
            <!--              }}</span>-->
            <button
              v-if="form.status === 'obligation'"
              class="searchBtn peyBtn"
              style="margin-left: 30px"
              @click="
                $fun.routerToPage(
                  '/OrderDetails?type=' +
                    item.statusName +
                    '&order_sn=' +
                    item.order_sn,
                  true
                )
              "
            >
              {{ $fanyi("前往付款") }}
            </button>
          </li>
        </ul>
      </div>
      <div class="allpag">
        <span></span>
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="form.currentPage"
          :page-size="10"
          :page-sizes="[10, 20, 30, 50]"
          layout=" prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getCurrentMonthFirst,
  getCurrentMonthLast,
} from "../../../../utlis/date";
import Dialog from "../../../../components/public/Dialog";
import axios from "axios";
import Europess from "../../../../api/wangZhi";
let Europe = Europess + "/client/";
export default {
  data() {
    return {
      value1: null,
      form: {
        status: "all",
        page: 1,
        pageSize: 10,
        searchText: "",
        startTime: "",
        endTime: "",
      },
      fullscreenLoading: false,
      tableData: [],
      total: 0,
      tableOption: [], //表格显示哪些选项
      config: {
        top: "20vh",
        width: "500px",
        title: this.$fanyi("提示"),
        btnTxt: [this.$fanyi("确认"), this.$fanyi("取消")],
      },
      all: 0,
      ordersSumList: {
        temporary: 0,
        delete: 0,
        purchased: 0,
        purchase: 0,
        obligation: 0,
        offer: 0,
        all: 0,
      }, //商品列表各种类商品数据
    };
  },
  components: { Dialog },
  computed: {},
  created() {
    this.form.status = "all";
    this.form.page = 1;
    this.form.pageSize = 10;
    // 默认显示当月
    // this.form.startTime = getCurrentMonthFirst() + " 00:00:00";
    // this.form.endTime = getCurrentMonthLast() + " 23:59:59";
    // this.value1 = [
    //   getCurrentMonthFirst() + " 00:00:00",
    //   getCurrentMonthLast() + " 23:59:59",
    // ];
    if (this.$route.query.status) {
      this.form.status = this.$route.query.status;
      this.$router.push({
        query: {},
      });
      this.getData(this.form);
    } else {
      this.getData(this.form, true);
    }
    this.ordersSum();
    (function() {
      if (window.history && window.history.pushState) {
        window.onpopstate = function() {
          window.history.pushState("forward", null, "");
          window.history.forward(1);
        };
      }
      window.history.pushState("forward", null, ""); //在IE中必须得有这两行
      window.history.forward(1);
    })();
  },
  methods: {
    textConversion(value) {
      // 把接口返回的状态名转换成西班牙语;
      let list = [
        {
          key: "temporary",
          value: this.$fanyi("预购订单"),
        },
        {
          key: "offer",
          value: this.$fanyi("报价中"),
        },
        {
          key: "obligation",
          value: this.$fanyi("等待付款"),
        },
        {
          key: "purchase",
          value: this.$fanyi("正在购买"),
        },
        {
          key: "purchased",
          value: this.$fanyi("购买完成"),
        },
        {
          key: "Pending financial confirmation",
          value: this.$fanyi("等待财务确认"),
        },
        {
          key: "cancel",
          value: this.$fanyi("已取消(订单状态)"),
        },
        {
          key: "delete",
          value: this.$fanyi("已删除(订单状态)"),
        },
      ];
      for (let i in list) {
        if (list[i].key == value) {
          return list[i].value;
        }
      }

      return value;
    },
    // 下载形式发票
    getFapiao(sn) {
      this.fullscreenLoading = true;
      let params = {
        order_sn: sn,
      };

      axios
        .post(Europe + "download.orderDetail", params, {
          responseType: "blob", // 1.首先设置responseType对象格式为 blob:
        })
        .then((res) => {
          this.fullscreenLoading = false;
          if (res.size < 200) {
            return this.$message.warning(this.$fanyi("操作失败"));
          }
          let blob = new Blob([res], {
            type: "application/vnd.ms-excel",
          });
          // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
          let url = window.URL.createObjectURL(blob); // 3.创建一个临时的url指向blob对象

          // 4.创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
          let a = document.createElement("a");
          a.href = url;
          a.download = "order_detail_" + sn + ".xlsx";
          a.click();
          // 5.释放这个临时的对象url
          window.URL.revokeObjectURL(url);
          this.diaShow = !this.diaShow;
        })
        .catch((error) => {
          this.$message(this.$fanyi(error.message));
        });
    },
    setFromDate() {
      if (this.value1) {
        this.form.startTime = this.value1[0];
        this.form.endTime = this.value1[1];
      } else {
        this.form.startTime = "";
        this.form.endTime = "";
      }
    },
    // 获取每个订单数量
    ordersSum() {
      this.$api
        .ordersSum()
        .then((res) => {
          if (res.data.length == 0) return (this.ordersSumList.all = 0);
          this.ordersSumList = res.data;
        })
        .catch((err) => {});
    },
    // 获取数据，传fir代表页面打开时请求数据的场合
    getData(datas, fir) {
      this.tableData = [];
      this.$api
        .orderList(datas)
        .then((res) => {
          //预购订单有数据的时候默认展示预购订单，否则展示全部订单
          if (fir == true && res.data.data.length == 0) {
            this.form.status = "all";
            this.getData(this.form);
          }

          this.tableData = res.data;
          this.total = res.data.total;
          this.form.currentPage = res.data.currentPage;
          this.$forceUpdate();
        })
        .catch((err) => {});
    },
    // 删除订单,根据订单状态分为硬删和软删
    delOrder(order_sn) {
      this.$refs.dialog.open(
        this.$fanyi("是否取消选中的订单"),
        () => {
          let datas = {
            order_sn: order_sn,
            deleteReason: this.$fanyi("删除"),
          };
          if (this.form.status == "temporary") {
            this.$api.orderTampDelete(datas).then((res) => {
              //console.log("orderTampDelete", res);
              if (res.code != 0) return;

              this.getData(this.form);
              this.ordersSum();
            });
          } else {
            this.$api.orderRecycling(datas).then((res) => {
              //console.log("orderRecycling", res);
              if (res.code != 0) return;

              this.getData(this.form);
              this.ordersSum();
            });
          }
        },
        () => {}
      );
    },
    handleCurrentChange(val) {
      this.form.page = val;
      this.getData(this.form);
    },
  },
};
</script>
<style lang="scss" scoped>
#userOrder {
  @import "../../../../css/mixin";
  width: 100%;

  button {
    cursor: pointer;
  }

  .rowAndCenter {
    display: flex;
    align-items: center;
  }

  > nav {
    height: 50px;
    display: flex;

    > span {
      display: flex;
      align-items: center;
      justify-content: center;
      // width: 130px;
      height: 50px;
      padding: 10px;
      background: #e8e8e8;
      border: 1px solid #e1e1e1;
      font-size: 12px;
      text-align: center;

      color: #000000;
      line-height: 16px;

      cursor: pointer;

      &.active {
        background-color: #ffffff;
        border-bottom: none;
      }

      .label {
        display: inline-block;
        margin-right: 7px;
      }

      .numberCount {
        width: 25px;
        height: 25px;
        background: #fbbb21;
        border-radius: 50%;
        font-size: 10px;
        color: #fff;
        text-align: center;
        line-height: 25px;
      }
    }
  }

  > div {
    // min-height: 720px;
    background: #ffffff;
    border: 1px solid #e1e1e1;
    border-top: none;
    padding: 20px 20px 37px 20px;

    .searchBox {
      display: flex;
      margin-bottom: 20px;

      .myInput {
        width: 400px;
        height: 40px;

        position: relative;
        display: flex;
        align-items: center;
        overflow: hidden;
        margin-right: 20px;

        > i {
          width: 20px;
          height: 24px;
          margin: 9px 11px 9px 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
        }

        > input {
          border: none;
          border-radius: 4px;
          border: 1px solid #c0c4cc;
          width: 500px;
          height: 100%;
          font-size: 14px;
          padding-left: 40px;
          padding-right: 10px;
          transition: 0.3s;

          &:focus {
            border-color: #1e2997;
          }
        }
      }

      /deep/.el-date-editor {
        height: 40px;
        margin-right: 20px;
        border-radius: 4px;

        .el-range-input {
          font-size: 14px;
        }

        .el-range-separator {
          width: 50px;
        }
      }

      .searchBtn {
        width: 90px;
        height: 40px;
        background: $homePageSubjectColor;
        border-radius: 4px;

        color: #ffffff;
        font-size: 16px;
      }
    }

    .dataBody {
      margin-bottom: 40px;

      > ul {
        display: flex;

        // width: 1100px;
        > li {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          // line-height: 56px;
          font-size: 12px;

          color: #000000;

          // 设置一列
          &.oNO {
            flex: 0.33;
          }

          &.orderNumber {
            flex: 0 0 180px;
          }

          &.datePresentation {
            flex: 0 0 210px;
            text-align: center;
          }
        }
      }

      ul.ohead {
        height: 56px;
        background: #f0f0f0;

        li {
          overflow: hidden;
        }

        .tableHeadCon {
          cursor: default;
          width: 100%;
          text-align: center;
          line-height: 14px;
        }
      }

      ul.obody {
        height: 60px;
        margin: 0 -1px -1px 0;
        border: 1px solid #ededed;

        li {
          border-right: solid 1px #ededed;
          border-bottom: solid 1px #ededed;
          margin: 0 -1px -1px 0;
        }

        .searchBtn {
          width: 80px;
          height: 40px;
          background: $homePageSubjectColor;
          border-radius: 4px;

          color: #ffffff;
          font-size: 16px;
        }

        .oNO {
          flex: 0.33;
        }

        .orderNumber {
          button {
            font-size: 14px;

            color: #1e2997;
            line-height: 20px;
            background-color: white;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        .datePresentation {
          text-align: center;
        }

        .upLoad {
          img {
            width: 18px;
            height: 18px;
            cursor: pointer;
          }
        }
      }
    }

    .allpag {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 25px 0;

      > a {
        display: inline-block;
        border: 1px solid #dcdfe6;
        height: 28px;
        background-color: white;
        text-align: center;
        padding: 0 10px;
        font-size: 13px;
        line-height: 28px;
        border-radius: 2px;
      }

      /* 分页被选中的页码样式 */
      /deep/ .el-pager li {
        margin: 0 3px;
        border-radius: 4px;
        box-shadow: 0px 0px 6px 0px rgba(61, 61, 61, 0.1);

        &:hover {
          color: $homePageSubjectColor;
        }

        &.active {
          background-color: $homePageSubjectColor;
          color: white;
        }
      }
    }
  }
}

.searchBtn.peyBtn {
  width: 100px !important;
}
</style>
