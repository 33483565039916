var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading.fullscreen.lock",value:(_vm.fullscreenLoading),expression:"fullscreenLoading",modifiers:{"fullscreen":true,"lock":true}}],attrs:{"id":"userOrder"}},[_c('Dialog',{ref:"dialog",attrs:{"config":_vm.config}}),_c('nav',[_c('span',{class:_vm.form.status == 'all' ? 'active' : '',on:{"click":function($event){_vm.form.status = 'all';
        _vm.$forceUpdate();
        _vm.form.page = 1;
        _vm.getData(_vm.form);}}},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$fanyi("所有订单")))]),_c('span',{staticClass:"numberCount"},[_vm._v(_vm._s(_vm.ordersSumList.all))])]),_c('span',{class:_vm.form.status == 'temporary' ? 'active' : '',on:{"click":function($event){_vm.form.status = 'temporary';
        _vm.$forceUpdate();
        _vm.form.page = 1;
        _vm.getData(_vm.form);}}},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$fanyi("临时保存的订单")))]),_c('span',{staticClass:"numberCount"},[_vm._v(_vm._s(_vm.ordersSumList.temporary))])]),_c('span',{class:_vm.form.status == 'offer' ? 'active' : '',on:{"click":function($event){_vm.form.status = 'offer';
        _vm.$forceUpdate();
        _vm.form.page = 1;
        _vm.getData(_vm.form);}}},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$fanyi("报价中")))]),_c('span',{staticClass:"numberCount"},[_vm._v(_vm._s(_vm.ordersSumList.offer))])]),_c('span',{class:_vm.form.status == 'obligation' ? 'active' : '',on:{"click":function($event){_vm.form.status = 'obligation';
        _vm.$forceUpdate();
        _vm.form.page = 1;
        _vm.getData(_vm.form);}}},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$fanyi("待处理的订单")))]),_c('span',{staticClass:"numberCount"},[_vm._v(_vm._s(_vm.ordersSumList.obligation))])]),_c('span',{class:_vm.form.status == 'delete' ? 'active' : '',on:{"click":function($event){_vm.form.status = 'delete';
        _vm.$forceUpdate();
        _vm.form.page = 1;
        _vm.getData(_vm.form);}}},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$fanyi("已取消的订单")))]),_c('span',{staticClass:"numberCount"},[_vm._v(_vm._s(_vm.ordersSumList.delete + _vm.ordersSumList.cancel + 0))])])]),_c('div',[_c('div',{staticClass:"searchBox"},[_c('div',{staticClass:"myInput"},[_c('i',{staticClass:"el-icon-search"}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.searchText),expression:"form.searchText"}],attrs:{"type":"text","placeholder":_vm.$fanyi('请输入关键词搜索')},domProps:{"value":(_vm.form.searchText)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getData(_vm.form)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "searchText", $event.target.value)}}})]),_c('el-date-picker',{attrs:{"type":"datetimerange","range-separator":_vm.$fanyi('至'),"start-placeholder":_vm.$fanyi('开始日期'),"end-placeholder":_vm.$fanyi('结束日期'),"format":"dd-MM-yyyy HH:mm:ss","value-format":"yyyy-MM-dd HH:mm:ss","default-time":['00:00:00', '23:59:59']},on:{"change":_vm.setFromDate},model:{value:(_vm.value1),callback:function ($$v) {_vm.value1=$$v},expression:"value1"}}),_c('button',{staticClass:"searchBtn",on:{"click":function($event){_vm.form.page = 1;
          _vm.getData(_vm.form);}}},[_vm._v(" "+_vm._s(_vm.$fanyi("搜索"))+" ")])],1),_c('div',{staticClass:"dataBody"},[_c('ul',{staticClass:"ohead"},[_vm._m(0),_c('li',{staticClass:"orderNumber"},[_c('span',{staticClass:"tableHeadCon",attrs:{"title":_vm.$fanyi('订单号')}},[_vm._v(_vm._s(_vm.$fanyi("订单号")))])]),(_vm.form.status != 'temporary')?_c('li',{staticClass:"datePresentation"},[_c('span',{staticClass:"tableHeadCon",attrs:{"title":_vm.$fanyi('提出日期')}},[_vm._v(_vm._s(_vm.$fanyi("提出日期")))])]):_vm._e(),(_vm.form.status != 'temporary' && _vm.form.status != 'obligation')?_c('li',{staticClass:"datePresentation"},[_c('span',{staticClass:"tableHeadCon",attrs:{"title":_vm.$fanyi('付款日期')}},[_vm._v(_vm._s(_vm.$fanyi("付款日期")))])]):_vm._e(),(_vm.form.status != 'temporary' && _vm.form.status != 'obligation')?_c('li',{staticClass:"datePresentation"},[_c('span',{staticClass:"tableHeadCon",attrs:{"title":_vm.$fanyi('购买完成日期')}},[_vm._v(" "+_vm._s(_vm.$fanyi("购买完成日期")))])]):_vm._e(),(['purchase', 'purchased'].indexOf(_vm.form.status) != -1)?_c('li',{staticClass:"upLoad"},[_c('span',{staticClass:"tableHeadCon",attrs:{"title":_vm.$fanyi('导出excel')}},[_vm._v(_vm._s(_vm.$fanyi("导出excel")))])]):_vm._e(),_c('li',{staticStyle:{"text-align":"center"}},[_c('span',{staticClass:"tableHeadCon",attrs:{"title":_vm.$fanyi('订单状态')}},[_vm._v(_vm._s(_vm.$fanyi("订单状态")))])]),(['temporary', 'obligation'].indexOf(_vm.form.status) != -1)?_c('li',[_c('span',{staticClass:"tableHeadCon",attrs:{"title":_vm.$fanyi('操作')}},[_vm._v(_vm._s(_vm.$fanyi("操作")))])]):_vm._e()]),_vm._l((_vm.tableData.data),function(item,index){return _c('ul',{key:_vm.$fanyi('表格') + index,staticClass:"obody"},[_c('li',{staticClass:"oNO"},[_vm._v(_vm._s(index + 1))]),_c('li',{staticClass:"orderNumber"},[_c('button',{on:{"click":function($event){return _vm.$fun.routerToPage(
                '/OrderDetails?type=' +
                  item.statusName +
                  '&order_sn=' +
                  item.order_sn,
                true
              )}}},[_vm._v(" "+_vm._s(item.order_sn)+" ")])]),(_vm.form.status != 'temporary')?_c('li',{staticClass:"datePresentation"},[_vm._v(" "+_vm._s(item.created_at)+" ")]):_vm._e(),(_vm.form.status != 'temporary' && _vm.form.status != 'obligation')?_c('li',{staticClass:"datePresentation"},[_vm._v(" "+_vm._s(item.order_time_node ? item.order_time_node.datetime_pay_confirm ? item.order_time_node.datetime_pay_confirm : "————" : "————")+" ")]):_vm._e(),(_vm.form.status != 'temporary' && _vm.form.status != 'obligation')?_c('li',{staticClass:"datePresentation"},[_vm._v(" "+_vm._s(item.order_time_node ? item.order_time_node.datetime_purchase_complete ? item.order_time_node.datetime_purchase_complete : "————" : "————")+" ")]):_vm._e(),(['purchase', 'purchased'].indexOf(_vm.form.status) != -1)?_c('li',{staticClass:"upLoad",on:{"click":function($event){return _vm.getFapiao(item.order_sn)}}},[_c('img',{attrs:{"src":require("../../../../assets/newImg/shancg2.svg"),"alt":""}})]):_vm._e(),_c('li',{staticStyle:{"font-size":"12px","text-align":"center"}},[_vm._v(" "+_vm._s(item.is_offer_error ? _vm.$fanyi("事故") : _vm.textConversion(item.statusName))+" ")]),(['temporary', 'obligation'].indexOf(_vm.form.status) != -1)?_c('li',[_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.delOrder(item.order_sn)}}},[_vm._v(_vm._s(_vm.$fanyi("取消")))]),(_vm.form.status === 'obligation')?_c('button',{staticClass:"searchBtn peyBtn",staticStyle:{"margin-left":"30px"},on:{"click":function($event){return _vm.$fun.routerToPage(
                '/OrderDetails?type=' +
                  item.statusName +
                  '&order_sn=' +
                  item.order_sn,
                true
              )}}},[_vm._v(" "+_vm._s(_vm.$fanyi("前往付款"))+" ")]):_vm._e()]):_vm._e()])})],2),_c('div',{staticClass:"allpag"},[_c('span'),_c('el-pagination',{attrs:{"current-page":_vm.form.currentPage,"page-size":10,"page-sizes":[10, 20, 30, 50],"layout":" prev, pager, next, jumper","total":_vm.total},on:{"current-change":_vm.handleCurrentChange}})],1)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"oNO"},[_c('span',{staticClass:"tableHeadCon"},[_vm._v("Nº")])])}]

export { render, staticRenderFns }